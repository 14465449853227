<template>
  <div v-if="!showDontHaveService" id="agenda" class="agenda">
    <v-container>
      <v-row align="center" justify="center">
        <v-card elevation="0" max-width="1100" width="100%" class="transparent mb-5">
          <v-container grid-list-xl fluid>
            <v-layout wrap>
              <v-flex lg6 md6>
                <v-card class="mb-4">
                  <v-toolbar flat>
                    <v-toolbar-title class="subheading">{{ $t('agenda.configuracion') }}</v-toolbar-title>
                  </v-toolbar>
                  <v-divider />
                  <WeeklySchedule
                    :weekdays="weekdays"
                    :schedule-id="scheduleId"
                    @scheduleModified="handleScheduleModified"
                    @dayOfMonthModified="handleDayOfMonthModified"
                    @loadSchedule="loadSchedule"
                  />
                </v-card>
              </v-flex>
              <v-flex lg6 md6>
                <v-card class="mb-4">
                  <v-toolbar flat>
                    <v-toolbar-title class="subheading">{{ $t('agenda.disponibilidad') }}</v-toolbar-title>
                    <v-spacer />
                  </v-toolbar>
                  <v-divider />
                  <v-card-text class="">
                    <v-flex xs12 lg12 mb-3>
                      <div style="text-align: center;">
                        <v-toolbar flat>
                          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                            {{ $t('agenda.hoy') }}
                          </v-btn>
                          <v-btn fab text small color="grey darken-2" @click="prev">
                            <v-icon small>
                              mdi-chevron-left
                            </v-icon>
                          </v-btn>
                          <v-btn fab text small color="grey darken-2" @click="next">
                            <v-icon small>
                              mdi-chevron-right
                            </v-icon>
                          </v-btn>
                          <v-toolbar-title v-if="$refs.calendar" />
                        </v-toolbar>
                      </div>
                      <div style="text-align: center;">
                        <v-calendar
                          ref="calendar"
                          v-model="focus"
                          color="primary"
                          :events="events"
                          :event-color="getEventColor"
                          :type="type"
                          :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                          :locale="this.$i18n.locale"
                          @click:event="showEvent"
                          @change="setDates"
                        />
                        <v-menu
                          v-model="selectedOpen"
                          :close-on-content-click="false"
                          :activator="selectedElement"
                          offset-x
                        >
                          <v-card color="grey lighten-4" min-width="350px" flat>
                            <v-toolbar :color="selectedEvent.color" dark>
                              <v-toolbar-title>{{ selectedEvent.event }}</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                              <span>{{ selectedEvent.details }}</span>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </div>
                    </v-flex>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex lg12 md12>
                <v-card class="mb-4">
                  <v-toolbar flat>
                    <v-toolbar-title class="subheading">{{ $t('agenda.nodisponible') }}</v-toolbar-title>
                    <v-spacer />
                  </v-toolbar>
                  <v-divider />
                  <v-card-text class="">
                    <nodisponibles :nodisponibles="busyDays" :idagenda="scheduleId" @loadSchedule="loadSchedule" />
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-row>
    </v-container>
    <v-dialog v-model="dialogLeaveWithoutSave" width="500">
      <v-card>
        <v-card-title class="headline lighten-2">{{ $t('common.titulo_salir_sin_guardar') }}</v-card-title>
        <v-divider />
        <v-card-text>{{ $t('common.salir_sin_guardar') }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey darken-1" text @click="dialogLeaveWithoutSave = false">{{ $t('common.cancel') }}</v-btn>
          <v-btn color="primary" depressed @click="confirmLeavePage">{{ $t('common.confirm') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tour name="myTour" :steps="steps" :options="myOptions" />
  </div>
  <div v-else>
    <no-service-granted view="agenda" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Nodisponibles from '@/components/ehealth/ajustes/Nodisponibles.vue';
import { haveEprescriptionActive } from '@/utils';
import NoServiceGranted from '@/components/common/NoServiceGranted.vue';
import WeeklySchedule from '@/components/ehealth/ajustes/WeeklySchedule';
import { getSchedule, createSchedule } from '@/views/settings/schedule/schedule.api';

export default {
  components: {
    WeeklySchedule,
    Nodisponibles,
    NoServiceGranted,
  },
  data: () => ({
    dialogLeaveWithoutSave: false,
    pageNext: {},
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    loading: true,
    scheduleId: 0,
    weekdays: [],
    daysSelected: [],
    time: null,
    busyDays: [],
    modifiedAgenda: false,
    events: [],
    start_date: '',
    end_date: '',
    focus: '',
    type: 'month',
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    todos: [
      {
        id: 1,
        description: '',
        date: { weekdays: [] },
        isCompleted: false,
        color: 'green',
        colorHex: '#43A047',
        fontcolor: '#fff',
      },
      {
        id: 2,
        description: '',
        date: [],
        isCompleted: false,
        color: 'red',
        colorHex: '#cb455b',
        fontcolor: '#fff',
      },
      {
        id: 3,
        description: '',
        date: [],
        isCompleted: false,
        color: 'orange',
        colorHex: '#9da021',
        fontcolor: '#fff',
      },
    ],
    steps: [
      { target: '[data-v-step="0"]', content: '' },
      { target: '[data-v-step="1"]', content: '' },
    ],
    myOptions: {
      useKeyboardNavigation: false,
      labels: {
        buttonSkip: vm.$t('tour.skip'),
        buttonPrevious: vm.$t('tour.prev'),
        buttonNext: vm.$t('tour.next'),
        buttonStop: vm.$t('tour.finish'),
      },
    },
    showDontHaveService: false,
  }),
  computed: {
    attributes() {
      return this.todos.map(t => ({
        key: `todo.${t.id}`,
        highlight: {
          backgroundColor: t.color,
        },
        contentStyle: {
          color: t.fontcolor,
        },
        dates: t.date,
        customData: t,
      }));
    },

    shouldShowTour() {
      return false;
    },

    ...mapState({
      appDisplayName: state => state.app.displayName,
    }),
  },
  watch: {
    busyDays: function(val) {
      const completeDays = [];
      const incompleteDays = [];
      val.forEach(function(busy) {
        const startDateData = busy.startDate.split('-');
        let dateToInsert = new Date(startDateData[0], startDateData[1], startDateData[2]);
        if (busy.startDate !== busy.endDate) {
          const endDateData = busy.endDate.split('-');
          dateToInsert = {
            start: new Date(startDateData[0], startDateData[1], startDateData[2]),
            end: new Date(endDateData[0], startDateData[1], startDateData[2]),
          };
        }
        if (busy.diaCompleto === 'Yes') {
          completeDays.push(dateToInsert);
        } else {
          incompleteDays.push(dateToInsert);
        }
      });
      this.todos[1].date = completeDays;
      this.todos[2].date = incompleteDays;
    },
  },
  mounted() {
    if (haveEprescriptionActive()) {
      this.showDontHaveService = true;
    }
    this.loadSchedule();
    this.$refs.calendar.checkChange();
  },

  methods: {
    ...mapActions('layout', ['showAlert']),

    async loadSchedule() {
      this.modifiedAgenda = false;
      let schedule = await getSchedule();
      if (typeof schedule?.weekdays === 'undefined') {
        await createSchedule();
        schedule = await getSchedule();
      }
      this.weekdays = schedule.weekdays;
      this.scheduleId = schedule.id;
      this.busyDays = schedule.busyDays;
      this.setActiveWeekdays();
      this.handleOnBoarding();
      this.updateEvents();
      this.isLoading = false;
    },

    setActiveWeekdays() {
      this.daysSelected = [];
      this.weekdays.forEach(function(day, index) {
        if (day.status) {
          const indexToAdd = index === 6 ? 1 : index + 2;
          this.daysSelected.push(indexToAdd);
        }
      }, this);
      this.todos[0].date = { weekdays: this.daysSelected };
    },

    handleOnBoarding() {
      if (this.daysSelected.length === 0 && this.shouldShowTour) {
        this.steps[0].content =
          this.$t('tour.configura_agenda', { name: this.appDisplayName }) +
          '<br>' +
          this.$t('tour.configura_hora_agenda');
        this.steps[1].content = this.$t('tour.guardar_cambios');
        this.$tours['myTour'].start();
      }
    },

    handleScheduleModified(value) {
      this.modifiedAgenda = value;
    },

    handleDayOfMonthModified(weekdaysChecked) {
      this.todos[0].date = { weekdays: weekdaysChecked };
    },

    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = '';
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateEvents() {
      const events = [];

      const notAllowDay = [];
      for (let i = 0; i < this.busyDays.length; i++) {
        const moreDetails =
          this.busyDays[i].diacompleto === 'Yes'
            ? this.$t('agenda.diacompleto')
            : this.busyDays[i].startTime + ' / ' + this.busyDays[i].endTime;
        if (notAllowDay.indexOf(new Date(this.busyDays[i].startDate).toISOString().substr(0, 10)) === -1) {
          notAllowDay.push(new Date(this.busyDays[i].startDate).toISOString().substr(0, 10));
          if (this.busyDays[i].startDate !== this.busyDays[i].endDate) {
            const minNotAlow = new Date(`${this.busyDays[i].startDate}T00:00:00`);
            const maxNotAlow = new Date(`${this.busyDays[i].endDate}T23:59:59`);
            const daysNotAlow = (maxNotAlow.getTime() - minNotAlow.getTime()) / 86400000;
            const date = new Date(this.busyDays[i].startDate);
            for (let j = 0; j < Math.round(daysNotAlow); j++) {
              if (notAllowDay.indexOf(date.toISOString().substr(0, 10)) === -1) {
                notAllowDay.push(date.toISOString().substr(0, 10));
              }
              date.setDate(date.getDate() + 1);
            }
          }
        }
        events.push({
          name: '',
          event: this.$t('calendario.nodisponible'),
          details: moreDetails + '. ' + this.busyDays[i].detalles,
          start: new Date(this.busyDays[i].startDate),
          end: new Date(this.busyDays[i].endDate),
          color: this.busyDays[i].diacompleto === 'Yes' ? 'red darken-1' : 'orange',
          timed: false,
        });
      }
      const date = new Date(this.start_date);
      for (let i = 0; i < 365; i++) {
        const d = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        const day = date.getDay() === 0 ? 6 : date.getDay() - 1;
        if (this.weekdays[day].status && notAllowDay.indexOf(date.toISOString().substr(0, 10)) === -1) {
          const firstDetails = this.weekdays[day].startTime + ' / ' + this.weekdays[day].endTime;
          let details = '';

          if (this.weekdays[day].startTime2 !== '' && this.weekdays[day].endTime2 !== '') {
            const secondDetails =
              'Segundo Turno: ' + this.weekdays[day].startTime2 + ' / ' + this.weekdays[day].endTime2;
            details = `
            ${firstDetails}
            ${secondDetails}
            `;
          } else {
            details = firstDetails;
          }
          events.push({
            name: '',
            event: 'Dia Disponible',
            details: details,
            start: new Date(d),
            end: new Date(d),
            color: 'green',
            timed: false,
          });
        }

        date.setDate(date.getDate() + 1);
      }

      this.events = events;
    },

    setDates({ start, end }) {
      this.start_date = start.date;
      this.end_date = end.date;
    },

    confirmLeavePage() {
      this.dialogLeaveWithoutSave = false;
      this.pageNext();
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.modifiedAgenda && to['name'] !== 'Login') {
      this.dialogLeaveWithoutSave = true;
      this.pageNext = next;
      next(false);
    } else {
      next();
    }
  },
};
</script>
<style lang="stylus" scoped>
.media
  &-cotent--wrap
  &-menu
    min-width: 260px
    border-right: 1px solid #eee
    min-height: calc(100vh - 50px - 64px);

  &-detail
    min-width: 300px
    border-left: 1px solid #eee
</style>
<style scoped lang="css">
.bounce-enter-active {
  animation: bounce-in 0.5s;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.v-picker__title primary {
  display: none !important;
}

.v-card__text {
  white-space: pre;
}
</style>
