<template>
  <v-card-text class="" data-v-step="0" data-testid="weekly-schedule">
    <v-flex xs12 lg12 mb-3>
      <v-expansion-panels popout>
        <v-expansion-panel v-for="(day, index) in weekdays" :key="index" class="mb-2">
          <div slot="header">
            <v-checkbox v-model="day.status" :label="days[index]" color="primary" />
          </div>
          <v-card flat>
            <v-card-title class="subtitle-1 lighten-2">{{ days[index] }}</v-card-title>
            <v-card-text>
              <v-layout wrap>
                <v-flex xs6 sm6 lg6>
                  <v-text-field
                    data-test="settings-input-start-time"
                    v-model="day.startTime"
                    type="time"
                    :label="$t('agenda.horainicio')"
                    :placeholder="$t('agenda.horainicio')"
                    append-icon="mdi-clock-outline"
                    @change="changeHourFirstTurn(index, 'start')"
                  />
                </v-flex>
                <v-flex xs6 sm6 lg6>
                  <v-text-field
                    data-test="settings-input-end-time"
                    v-model="day.endTime"
                    type="time"
                    :label="$t('agenda.horafin')"
                    :placeholder="$t('agenda.horafin')"
                    append-icon="mdi-clock-outline"
                    @change="changeHourFirstTurn(index, 'end')"
                  />
                </v-flex>
                <v-flex v-if="weekdays[index].secondRange" xs1 style="padding: 26px 0;">
                  <v-btn icon @click="removeSecondTurn(index)">
                    <v-icon color="grey">mdi-delete</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex v-if="weekdays[index].secondRange" xs5 sm5 lg5>
                  <v-text-field
                    v-model="day.startTime2"
                    type="time"
                    :label="$t('agenda.horainicio') + ' 2'"
                    :placeholder="$t('agenda.horainicio')"
                    append-icon="mdi-clock-outline"
                    @change="changeHourSecondTurn(index, 'start')"
                  />
                </v-flex>
                <v-flex v-if="weekdays[index].secondRange" xs6 sm6 lg6>
                  <v-text-field
                    v-model="day.endTime2"
                    type="time"
                    :label="$t('agenda.horafin') + ' 2'"
                    :placeholder="$t('agenda.horafin')"
                    append-icon="mdi-clock-outline"
                    @change="changeHourSecondTurn(index, 'end')"
                  />
                </v-flex>
                <v-flex
                  v-if="!weekdays[index].secondRange && day.startTime !== '' && day.endTime !== ''"
                  class="flex_new_range"
                  xs12
                >
                  <v-btn class="btn_new_range" color="primary" rounded depressed small @click="newTurn(index)">
                    <v-icon>mdi-plus</v-icon>
                    {{ $t('agenda.nuevo_rango') }}
                  </v-btn>
                </v-flex>
              </v-layout>
              <br />
            </v-card-text>
          </v-card>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-flex>
    <v-flex xs12 class="text-md-center">
      <v-btn data-test="settings-button-save" data-v-step="1" :disabled="disabledButton" color="primary" large @click="saveSchedule"
        >{{ $t('common.save') }} {{ $t('menu.agenda') }}
      </v-btn>
    </v-flex>
  </v-card-text>
</template>

<script>
import { getHeader, scheduleUrl } from '@/config/config';
import { mapActions } from 'vuex';

export default {
  name: 'WeeklySchedule',
  props: {
    weekdays: {
      type: Array,
      default: () => [],
    },
    scheduleId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    disabledButton: false,
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    days: [
      vm.$t('agenda.lunes'),
      vm.$t('agenda.martes'),
      vm.$t('agenda.miercoles'),
      vm.$t('agenda.jueves'),
      vm.$t('agenda.viernes'),
      vm.$t('agenda.sabado'),
      vm.$t('agenda.domingo'),
    ],
  }),

  methods: {
    ...mapActions('layout', ['showAlert']),

    changeHourFirstTurn(i, type) {
      const types = ['startTime', 'endTime', 'startTime2', 'endTime2'];
      let statusDay = true;

      for (const t of types) {
        this.setValidTime(i, t);
      }

      for (const t of types) {
        if (this.checkInvalidHour(this.weekdays[i][t])) {
          this.showAlert({ color: 'warning', icon: 'mdi-clock-outline', message: this.$t('errors.incorrect_hour') });
          this.weekdays[i].status = false;
          return false;
        }
      }

      if (type === 'start') {
        if (this.weekdays[i].startTime > this.weekdays[i].endTime && this.weekdays[i].endTime !== '') {
          this.showAlert({
            color: 'warning',
            icon: 'mdi-clock-outline',
            message: this.$t('agenda.hora_inicio_debe_ser'),
          });
          statusDay = false;
        }
      } else if (type === 'end') {
        if (
          (this.weekdays[i].startTime > this.weekdays[i].endTime && this.weekdays[i].startTime !== '') ||
          (this.weekdays[i].startTime2 < this.weekdays[i].endTime && this.weekdays[i].startTime2 !== '')
        ) {
          this.showAlert({
            color: 'warning',
            icon: 'mdi-clock-outline',
            message: this.$t('agenda.hora_inicio_debe_ser'),
          });
          statusDay = false;
        }
      }

      this.weekdays[i].status = statusDay;
      this.$emit('scheduleModified', true);
    },

    setValidTime(index, type) {
      if (this.weekdays[index][type] !== '') {
        const step = 5;
        const fullTime = this.weekdays[index][type].split(':');
        const exceedsMaxTime = Math.ceil(fullTime[1] / step) * step >= 60;
        const minuteTime = exceedsMaxTime ? 0 : Math.ceil(fullTime[1] / step) * step;
        const minute = minuteTime < 10 ? '0' + minuteTime : minuteTime;
        const hourTime = exceedsMaxTime && fullTime[0] < '23' ? parseInt(fullTime[0]) + 1 : fullTime[0];
        const hour = hourTime < 10 ? '0' + parseInt(hourTime) : hourTime;
        this.weekdays[index][type] = hour + ':' + minute;
      }
    },

    changeHourSecondTurn(i, type) {
      const types = ['startTime2', 'endTime2'];
      let statusDay = true;

      for (const t of types) {
        this.setValidTime(i, t);
      }

      for (const t of ['start', 'end']) {
        if (
          type === t &&
          ((this.weekdays[i].startTime2 > this.weekdays[i].endTime2 && this.weekdays[i].endTime2 !== '') ||
            (this.weekdays[i].startTime2 < this.weekdays[i].endTime && this.weekdays[i].endTime !== ''))
        ) {
          this.showAlert({
            color: 'warning',
            icon: 'mdi-clock-outline',
            message: this.$t('agenda.hora_inicio_debe_ser'),
          });
          statusDay = false;
        }
      }

      this.weekdays[i].status = statusDay;
      this.$emit('scheduleModified', true);
    },

    checkInvalidHour(hour) {
      const hourRegex = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      const correctHour = hourRegex.exec(hour);
      return hour !== '' && !correctHour;
    },

    removeSecondTurn(index) {
      this.weekdays[index].secondRange = false;
      this.weekdays[index].startTime2 = '';
      this.weekdays[index].endTime2 = '';
      this.$emit('scheduleModified', true);
    },

    newTurn(index) {
      if (!this.weekdays[index].secondRange) {
        this.weekdays[index].secondRange = true;
        this.weekdays[index].startTime2 = this.weekdays[index].endTime;
      }
    },

    validateHours() {
      let isCorrect = true;
      this.weekdays.forEach(function(day, index) {
        const types = ['startTime', 'endTime', 'startTime2', 'endTime2'];
        for (const t of types) {
          this.setValidTime(index, t);
        }

        for (const t of types) {
          if (this.checkInvalidHour(this.weekdays[index][t])) {
            this.showAlert({ color: 'warning', icon: 'mdi-clock-outline', message: this.$t('errors.incorrect_hour') });
            return false;
          }
        }

        if (day.startTime !== '' && day.endTime !== '') {
          const startHour1 = day.startTime.split(':')[0];
          const startMinutes1 = day.startTime.split(':')[1];
          const startTimeDateTime1 = new Date();
          startTimeDateTime1.setHours(startHour1, startMinutes1, 0);

          const endHour1 = day.endTime.split(':')[0];
          const endMinutes1 = day.endTime.split(':')[1];
          const endTimeDateTime1 = new Date();
          endTimeDateTime1.setHours(endHour1, endMinutes1, 0);

          const diff = endTimeDateTime1 - startTimeDateTime1;
          const isEqual = diff < 10;
          if (endTimeDateTime1 < startTimeDateTime1 || isEqual) {
            isCorrect = false;
            const msg =
              this.$t('agenda.hora_inicio_dia', { dia: day.nombre }) + '. ' + this.$t('agenda.hora_inicio_debe_ser');
            this.showAlert({ color: 'warning', icon: 'mdi-clock-outline', message: msg });
            return false;
          }
        }
        if (day.startTime2 !== '' && day.endTime2 !== '') {
          const startHour2 = day.startTime2.split(':')[0];
          const startMinutes2 = day.startTime2.split(':')[1];
          const startTimeDateTime2 = new Date();
          startTimeDateTime2.setHours(startHour2, startMinutes2, 0);

          const endHour2 = day.endTime2.split(':')[0];
          const endMinutes2 = day.endTime2.split(':')[1];
          const endTimeDateTime2 = new Date();
          endTimeDateTime2.setHours(endHour2, endMinutes2, 0);

          const diffHours = endTimeDateTime2 - startTimeDateTime2;
          const areEqual = diffHours < 10;

          if (endTimeDateTime2 < startTimeDateTime2 || areEqual) {
            isCorrect = false;
            const msg =
              this.$t('agenda.hora_inicio_dia', { dia: day.nombre }) + '. ' + this.$t('agenda.hora_inicio_debe_ser');
            this.showAlert({ color: 'warning', icon: 'mdi-clock-outline', message: msg });
            return false;
          }
        }
      }, this);

      return isCorrect;
    },

    saveSchedule() {
      const hoursValid = this.validateHours();

      if (!this.disabledButton && hoursValid) {
        this.disabledButton = true;
        this.$http
          .post(scheduleUrl + '/' + this.scheduleId, { weekdays: this.weekdays }, { headers: getHeader() })
          .then(response => {
            this.$tours['myTour'].stop();
            if (response.status === 200) {
              this.showAlert({ color: 'success', icon: 'mdi-check', message: this.$t('success.save_agenda') });
              this.$emit('loadSchedule');
            } else {
              this.showAlert({ color: 'error', icon: 'mdi-alert-circle', message: this.$t('errors.try_again') });
            }
          })
          .finally(() => {
            this.disabledButton = false;
          });
      }
    },
  },
};
</script>

<style scoped lang="css">
.flex_new_range {
  text-align: right;
  margin-top: -30px;
  margin-bottom: -20px;
}

.btn_new_range {
  font-size: 12px;
  margin: 0;
  padding: 0 4px 0 0;
}
</style>
